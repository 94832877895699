// Styles
import styled from 'styled-components';
import { StyledWithTheme } from '../theme';

import { Carousel } from 'react-responsive-carousel';

// main
export const Content = styled.main`
    display: block;
    width: 100%;
    box-sizing: border-box;
`;

// header
export const Header = styled.div`
    display: block;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px 16px 24px;

    @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
        padding: 12px;
    }

    .content{
        position: relative;
        width: 100%;
        max-width: 1024px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            flex-direction: column;
            align-items: center;
        }

        .left{
            flex-grow: 0;
            flex-shrink: 0;
            font-weight: bold;
            color: #FC1010;
            font-size: 64px;
            line-height: 64px;
        }

        .right{
            flex-grow: 1;
            flex-shrink: 1;
            font-size: 20px;
            line-height: 28px;
            padding: 6px 0 0 0;
            text-align: left;
            max-width: 530px;

            @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
                padding: 12px 0 0 0;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                max-width: none;
            }
        }
    }
`;

export const HeaderImage = styled.div`
    display: block;
    width: 100%;
    box-sizing: border-box;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 24px 64px 24px;

    @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
        padding: 32px 24px 32px 24px;
    }

    .photo-wrapper{
        position: relative;
        width: 100%;
        max-width: 818px;
        cursor: pointer;

        img{
            width: 100%;
        }

        .trailer-btn{
            position: absolute;
            width: 40%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .video{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #272526;
        }
    }
`;

export const HeaderDescription = styled.div`
    display: block;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 24px 64px 24px;

    @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
        padding: 32px 12px 32px 12px;
    }

    .content{
        display: block;
        width: 100%;
        max-width: 890px;
        box-sizing: border-box;
        font-size: 20px;
        line-height: 28px;
        text-align: center;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            font-size: 16px;
            line-height: 24px;
        }
    }
`;

// red line
export const RedLine = styled.div`
    display: block;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:after{
        display: block;
        content: "";
        width: 100%;
        max-width: 1024px;
        height: 2px;
        background: #FF0000;
    }
`;

// photographers
export const Photographers = styled.div`
    display: block;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 24px 64px 24px;

    @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
        padding: 32px 12px 32px 12px;
    }

    .title{
        text-align: center;
        font-weight: bold;
        font-size: 36px;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            font-size: 32px;
        }
    }

    .list{
        padding-top: 32px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        max-width: 890px;
        font-size: 20px;
        line-height: 28px;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            font-size: 16px;
            line-height: 24px;
            flex-direction: column;
            align-items: center;
            padding-top: 6px;
        }

        .left{
            width: 33%;
            text-align: center;

            @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
                width: 100%;
                padding-top: 12px;
            }
        }
        .center{
            width: 33%;
            text-align: center;

            @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
                width: 100%;
                padding-top: 24px;
            }
        }
        .right{
            width: 33%;
            text-align: center;

            @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
                width: 100%;
                padding-top: 24px;
            }
        }
    }
`;

// archives
export const Archives = styled.div`
    display: block;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px 64px 24px;

    @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
        padding: 0 12px 32px 12px;
    }

    .title{
        text-align: center;
        font-weight: bold;
        font-size: 36px;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            font-size: 32px;
        }
    }

    .list{
        padding-top: 32px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        max-width: 640px;
        font-size: 20px;
        line-height: 28px;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            font-size: 16px;
            line-height: 24px;
            flex-direction: column;
            align-items: center;
            padding-top: 6px;
        }

        .left{
            width: 50%;
            text-align: center;

            @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
                width: 100%;
                padding-top: 12px;
            }
        }
        .right{
            width: 50%;
            text-align: center;

            @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
                width: 100%;
                padding-top: 24px;
            }
        }
    }
`;

// gallery
export const Gallery = styled.div`
    position: relative;
    display: block;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #000000;
    padding: 32px 0 64px 0;

    @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
        padding: 24px 0 32px 0;
    }

    .title{
        text-align: center;
        font-weight: bold;
        font-size: 36px;
        color: #FFFFFF;
        padding: 0 0 32px 0;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            font-size: 32px;
        }
    }

    .gallery-content{
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
    }

    .arrow-wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-shrink: 1;
        flex-grow: 1;
        padding: 0 12px 0 12px;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            display: none;
        }

        .arrow{
            width: 65px;
            cursor: pointer;
            flex-shrink: 0;
            flex-grow: 0;

            @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenM}) {
                width: 45px;
            }
        }
    }
`;

export const PhotosCarousel = styled(Carousel)`
    position: relative;
    box-sizing: border-box;
    display: block;
    width: 100%;
    max-width: 818px;
    background: #000000;
    flex-shrink: 1;
    flex-grow: 0;

    @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenM}) {
        width: 100%;
    }

    .slide{
        background: transparent !important;
    }
    .dot{
        box-shadow: none !important;
    }

    .photo{
        position: relative;
        box-sizing: border-box;
        width: 100%;
        max-width: 818px;
        display: flex;
        flex-direction: column;
        flex-grow: 0;
        flex-shrink: 0;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenM}) {
            max-width: 100%;
        }

        img{
            max-width: 100%;
        }

        .description{
            text-align: center;
            color: #999999;
            font-size: 14px;
            font-weight: normal;
            padding-top: 8px;
        }
    }
`;

// about a book
export const AboutABook = styled.div`
    display: block;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 24px 124px 24px;

    @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
        padding: 24px 12px 64px 12px;
    }

    .title{
        text-align: center;
        font-weight: bold;
        font-size: 36px;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            font-size: 32px;
        }
    }

    .list{
        padding-top: 32px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        max-width: 890px;
        font-size: 20px;
        line-height: 28px;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            font-size: 16px;
            line-height: 24px;
            flex-direction: column;
            align-items: center;
            padding-top: 6px;
        }

        .left{
            width: 33%;
            text-align: center;

            @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
                width: 100%;
                padding-top: 12px;
            }
        }
        .center{
            width: 33%;
            text-align: center;

            @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
                width: 100%;
                padding-top: 24px;
            }
        }
        .right{
            width: 33%;
            text-align: center;

            @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
                width: 100%;
                padding-top: 24px;
            }
        }
    }

    .info{
        text-align: center;
        width: 100%;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            font-size: 16px;
            line-height: 24px;
        }

        b{
            font-weight: bold;
        }

        a{
            font-weight: normal;
            text-decoration: underline;
            color: #000000;

            &:hover{
                text-decoration: none;
            }
        }
    }
`;

// audio video
export const AudioVideo = styled.div`
    display: block;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 24px 0 24px;

    @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
        padding: 24px 12px 0 12px;
    }

    .title{
        text-align: center;
        font-weight: bold;
        font-size: 36px;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            font-size: 32px;
        }
    }

    .list{
        padding-top: 32px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1024px;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            padding-top: 24px;
        }

        .list-item{
            display: block;
            width: 100%;
            max-width: 450px;
            box-sizing: border-box;
            flex-shrink: 1;
            padding-bottom: 64px;
            cursor: pointer;

            @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
                padding-bottom: 32px;
            }

            img{
                width: 100%;
            }

            .description{
                font-size: 20px;
                line-height: 28px;

                @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }
`;

// actions
export const Actions = styled.div`
    display: block;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 24px 124px 24px;

    @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
        padding: 24px 12px 64px 12px;
    }

    .title{
        text-align: center;
        font-weight: bold;
        font-size: 36px;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            font-size: 32px;
        }
    }

    .list{
        padding-top: 32px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        max-width: 890px;
        font-size: 20px;
        line-height: 28px;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            font-size: 16px;
            line-height: 24px;
            flex-direction: column;
            align-items: center;
            padding-top: 6px;
        }

        .left{
            width: 33%;
            text-align: center;

            @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
                width: 100%;
                padding-top: 12px;
            }
        }
        .center{
            width: 33%;
            text-align: center;

            @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
                width: 100%;
                padding-top: 24px;
            }
        }
        .right{
            width: 33%;
            text-align: center;

            @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
                width: 100%;
                padding-top: 24px;
            }
        }
        .center-left{
            width: 50%;
            text-align: center;

            @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
                width: 100%;
                padding-top: 24px;
            }
        }
        .center-right{
            width: 50%;
            text-align: center;

            @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
                width: 100%;
                padding-top: 24px;
            }
        }
    }

    .action{
        text-align: center;
        width: 100%;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            font-size: 16px;
            line-height: 24px;
        }

        b{
            font-weight: bold;
        }

        a{
            font-weight: normal;
            text-decoration: underline;
            color: #000000;

            &:hover{
                text-decoration: none;
            }
        }
    }
`;

// footer
export const Footer = styled.div`
    display: block;
    width: 100%;
    box-sizing: border-box;

    .line-1{
        height: 124px;
        background: #CDCDCD;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            height: 64px;
        }
    }
    .line-2{
        height: 124px;
        background: #9A9A9A;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            height: 64px;
        }
    }
    .line-3{
        height: 124px;
        background: #666666;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            height: 64px;
        }
    }
    .line-4{
        height: 124px;
        background: #333333;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            height: 64px;
        }
    }
    .line-5{
        height: 124px;
        text-align: center;
        background: #000000;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
            height: 64px;
        }

        a{
            color: #ff0000;
            text-decoration: none;
            font-size: 20px;
            line-height: 28px;
            font-weight: bold;

            @media (max-width: ${({ theme }: StyledWithTheme) => theme.screens.screenS}) {
                font-size: 16px;
                line-height: 24px;
            }

            &:hover{
                text-decoration: underline;
            }
        }
    }
`;
