import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import themeObject from '../theme'
import { GlobalStyles } from '../theme/global'

import Photo01Preview from "../photos/01_preview";
import Photo02Preview from "../photos/02_preview";
import Photo03Preview from "../photos/03_preview";
import Photo04Preview from "../photos/04_preview";
import Photo05Preview from "../photos/05_preview";
import Photo06Preview from "../photos/06_preview";
import Photo07Preview from "../photos/07_preview";
import Photo08Preview from "../photos/08_preview";
import Photo09Preview from "../photos/09_preview";
import Photo10Preview from "../photos/10_preview";
import Photo11Preview from "../photos/11_preview";
import Photo12Preview from "../photos/12_preview";
import TrailerPreview from "../photos/trailer_preview";
import ArchivCT2401Preview from "../photos/archiv_ct24_01_preview";
import AkceDymPreview from "../photos/akce_dym_preview";
import ZpravyPreview from "../photos/zpravy_preview";
import StadionPreview from "../photos/stadion_preview";
import SmahelPreview from "../photos/smahel_preview";
import EkoTydenPreview from "../photos/ekotyden_preview";
import OfPreview from "../photos/of_preview";
import RadioPreview from "../photos/radio_preview";
import JanaDvorska01Preview from "../photos/jana_dvorska_01_preview";
import JanaDvorska02Preview from "../photos/jana_dvorska_02_preview";
import WankemPreview from "../photos/wankem-preview";
import VolaniPreview from "../photos/volani-preview";
import JanSajdl01Preview from '../photos/jan_sajdl_01_preview';
import PavelHorak01Preview from '../photos/pavel_horak_01_preview';
import PetrVeselsky01Preview from '../photos/petr_veselsky_01_preview';

import TrailerSVG from '../images/trailer-icon.inline.svg';
import ArrowLeftSVG from '../images/arrow-left.inline.svg';
import ArrowRightSVG from '../images/arrow-right.inline.svg';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../theme/fonts.css'

/// Styles
import {
    Content,
    Header,
    HeaderImage,
    HeaderDescription,
    RedLine,
    Photographers,
    Archives,
    Gallery,
    PhotosCarousel,
    AudioVideo,
    Actions,
    Footer,
    AboutABook
} from '../styles/index'

const PHOTOS_COUNT = 12;

const IndexPage = () => {

    const [showTrailVideo, setShowTrailVideo] = useState(false);
    const [activePhoto, setActivePhoto] = useState(0);

    const onLeftArrow = () => {
        let newPhoto = activePhoto - 1;
        if (newPhoto < 0) {
            newPhoto = (PHOTOS_COUNT - 1);
        }
        setActivePhoto(newPhoto);
    }

    const onRightArrow = () => {
        let newPhoto = activePhoto + 1;
        if (newPhoto >= PHOTOS_COUNT) {
            newPhoto = 0;
        }
        setActivePhoto(newPhoto);
    }

    const onTrailer = () => {
        setShowTrailVideo(true);
    }

    return (
        <ThemeProvider theme={themeObject}>
            <GlobalStyles />

            <Helmet
                htmlAttributes={{
                    lang: 'cs',
                    amp: undefined
                }}
                title='Inverze 89 - Nová fotografická publikace mapuje ekologické demonstrace v listopadu 1989 a návazné sametové dění v Teplicích.'
            >
                <meta http-equiv="Pragma" content="no-cache" />
                <meta http-equiv="Expires" content="-1" />
                <meta http-equiv="CACHE-CONTROL" content="NO-CACHE, no-store, must-revalidate" />
                <meta name="robots" content="all,follow" />
                <meta name='googlebot' content='index,follow,snippet,archive' />

                <meta name='description' content='Nová fotografická publikace mapuje ekologické demonstrace v listopadu 1989 a návazné sametové dění v Teplicích.' />
                <meta name='keywords' content='Teplice, 1989, Ekologické demonstrace, Iverze, Kniha, Fotografie, Smog, Revoluce, 30 let' />

                <meta property='og:title' content='Inverze 89' />
                <meta property='og:description' content='Nová fotografická publikace mapuje ekologické demonstrace v listopadu 1989 a návazné sametové dění v Teplicích.' />
                <meta property='og:type' content='website' />
                <meta property='og:image' content='/share.jpg' />
                <meta property='og:image:alt' content='Inverze 89' />
                <meta property='og:image:width' content='819' />
                <meta property='og:image:height' content='430' />
                <meta property='og:url' content={`https://www.inverze89.cz`} />
            </Helmet>

            <Content>

                <Header>
                    <div className="content">
                        <div className="left">Inverze 89</div>
                        <div className="right">Nová fotografická publikace mapuje ekologické demonstrace v listopadu 1989 a návazné sametové dění v Teplicích.</div>
                    </div>
                </Header>

                <HeaderImage>
                    <div className="photo-wrapper" onClick={onTrailer}>
                        <TrailerPreview />
                        <TrailerSVG className="trailer-btn" />
                        {showTrailVideo && (
                            <video className="video" controls autoPlay muted controlsList="nodownload">
                                <source src="https://firebasestorage.googleapis.com/v0/b/inverze-89.appspot.com/o/videos%2Ftrailer_optimized.mp4?alt=media&token=835982cc-b681-475c-a3a5-9fc533389762" type="video/mp4" />
                            </video>
                        )}
                    </div>
                </HeaderImage>

                <HeaderDescription>
                    <div className="content">
                        V listopadu 1989 došlo v severočeských Teplicích k nepovoleným demonstracím. Neúnosná ekologická situace v podobě setrvalého atmosferického znečištění dohnala Tepličany do ulic. Poté, co se ve městě objevily letáky vyrobené a distribuované mladým učněm Zbyškem „Oněginem“ Jindrou a jeho přáteli, uskutečnily se v centru města 11. listopadu 1989 a v několika následujících dnech demonstrace za čistší vzduch a řešení neutěšené situace životního prostředí. V těchto dnech došlo rovněž k sepsání petice a dohodě s místními představiteli režimu, že již 20. listopadu dojde na zimním stadionu k veřejnému mítinku, kde by se měla situace dále řešit. Mezitím se však události v naší zemi dále vyvinuly a ekologická témata se rozšířila o mnohé další požadavky.<br />
                        <br />
                        Tato publikace obsahuje jak fotograﬁe ze zmíněných demonstrací, tak z dalších návazných akcí a událostí na Teplicku v období sametové revoluce. V jednom souboru se zde střetávají snímky profesionálních i amatérských fotografů a dokumentátorů, které se po třiceti letech snaží připomenout atmosféru tehdejšího revolučního dění.
                    </div>
                </HeaderDescription>

                <RedLine />

                <Photographers>
                    <div className="title">Fotografové</div>
                    <div className="list">
                        <div className="left">
                            Petr&nbsp;Beránek<br />Olaf&nbsp;Deutsch<br />Marek&nbsp;Fujdiak<br />Jan&nbsp;Kašpar<br />František&nbsp;Kornalík
                        </div>
                        <div className="center">
                            Kristian&nbsp;Körschner<br />Libor&nbsp;Zavoral<br />Petr&nbsp;Kuranda<br />Věra&nbsp;Lukášková<br />Milan&nbsp;Nový
                        </div>
                        <div className="right">
                            Miroslav&nbsp;Rada<br />Jiří&nbsp;Reissig<br />Petr&nbsp;Stolař<br />Pavel&nbsp;Szirotný<br />Zdeněk&nbsp;Traxler
                        </div>
                    </div>
                </Photographers>

                <Archives>
                    <div className="title">Své archivy poskytli</div>
                    <div className="list">
                        <div className="left">
                            Věra&nbsp;Bartošková<br />Jana&nbsp;Dvorská<br />Marek&nbsp;Fujdiak<br />Pavel&nbsp;Horák<br />Radim&nbsp;Neuvirt<br />Kamil&nbsp;Vacek<br />Štěpán&nbsp;Veselský
                        </div>
                        <div className="right">
                            Archiv&nbsp;bezpečnostních&nbsp;složek<br />Regionální&nbsp;muzeum&nbsp;Teplice<br />Státní&nbsp;okresní&nbsp;archiv&nbsp;Teplice<br />Ústav&nbsp;pro&nbsp;soudobé&nbsp;dějiny<br />Ústav&nbsp;pro&nbsp;studium&nbsp;totalitních&nbsp;režimů<br />Skaut&nbsp;Teplice<br />Zpravodajství ČT
                        </div>
                    </div>
                </Archives>

                <Gallery>
                    <div className="title">Galerie</div>
                    <div className="gallery-content">
                        <div className="arrow-wrapper">
                            <ArrowLeftSVG className="arrow" onClick={onLeftArrow} />
                        </div>
                        <PhotosCarousel
                            showArrows={false}
                            showStatus={false}
                            showThumbs={false}
                            infiniteLoop={true}
                            autoPlay={false}
                            showIndicators={false}
                            swipeScrollTolerance={50}
                            selectedItem={activePhoto}
                        >
                            <div className="photo">
                                <Photo01Preview />
                                <div className="description">Petr Beránek</div>
                            </div>
                            <div className="photo">
                                <Photo02Preview />
                                <div className="description">Marek Fujdiak</div>
                            </div>
                            <div className="photo">
                                <Photo03Preview />
                                <div className="description">archiv Marka Fujdiaka</div>
                            </div>
                            <div className="photo">
                                <Photo04Preview />
                                <div className="description">Archiv bezpečnostních složek</div>
                            </div>
                            <div className="photo">
                                <Photo05Preview />
                                <div className="description">archiv Marka Fujdiaka</div>
                            </div>
                            <div className="photo">
                                <Photo06Preview />
                                <div className="description">Petr Stolař</div>
                            </div>
                            <div className="photo">
                                <Photo07Preview />
                                <div className="description">Zdeněk Traxler</div>
                            </div>
                            <div className="photo">
                                <Photo08Preview />
                                <div className="description">Zdeněk Traxler</div>
                            </div>
                            <div className="photo">
                                <Photo09Preview />
                                <div className="description">Milan Nový</div>
                            </div>
                            <div className="photo">
                                <Photo10Preview />
                                <div className="description">Miroslav Rada</div>
                            </div>
                            <div className="photo">
                                <Photo11Preview />
                                <div className="description">Zdeněk Traxler</div>
                            </div>
                            <div className="photo">
                                <Photo12Preview />
                                <div className="description">Věra Lukášková</div>
                            </div>
                        </PhotosCarousel>
                        <div className="arrow-wrapper">
                            <ArrowRightSVG className="arrow" onClick={onRightArrow} />
                        </div>
                    </div>
                </Gallery>

                <AboutABook>
                    <div className="title">O knize</div>
                    <div className="list">
                        <div className="left">
                            <div className="info">
                                <b>Rozměry</b><br />
                                200x280 mm
                            </div>
                        </div>

                        <div className="center">
                            <div className="action">
                                <b>Vazba</b><br />
                                V4
                            </div>
                        </div>

                        <div className="right">
                            <div className="action">
                                <b>Počet stran</b><br />
                                220
                            </div>
                        </div>
                    </div>
                    <div className="list">
                        <div className="left">
                            <div className="info">
                                <b>Editoři</b><br />
                                Martin Fuksa, Martin Ponec
                            </div>
                        </div>

                        <div className="center">
                            <div className="action">
                                <b>Vydal</b><br />
                                Temory z.s.
                            </div>
                        </div>

                        <div className="right">
                            <div className="action">
                                <b>Cena</b><br />
                                600Kč
                            </div>
                        </div>
                    </div>
                </AboutABook>

                <RedLine />

                <AudioVideo>
                    <div className="title">Audio/Video</div>
                    <div className="list">
                        <div className="list-item" onClick={() => { window.open('https://www.facebook.com/archivct24/videos/492933764642368/', '_blank'); }}>
                            <ArchivCT2401Preview />
                            <div className="description">24/11/1989<br />Setkání s herci Realistického divadla<br />Originální videožurnál</div>
                        </div>
                        <div className="list-item" onClick={() => { window.open('https://www.facebook.com/archivct24/posts/2891442137535551', '_blank'); }}>
                            <StadionPreview />
                            <div className="description">20/11/1989<br />Míting na zimním stadionu<br />Aktuality ČST</div>
                        </div>
                        <div className="list-item" onClick={() => { window.open('https://www.ustrcr.cz/uvod/listopad-1989/galerie/listopad-1989/', '_blank'); }}>
                            <AkceDymPreview />
                            <div className="description">13/11/1989<br />Demonstrace - náměstí Zd. Nejedlého<br />ÚSTR</div>
                        </div>
                        <div className="list-item" onClick={() => { window.open('https://www.facebook.com/archivct24/videos/vb.1273240469355734/2332431547069164/?type=2&theater', '_blank'); }}>
                            <ZpravyPreview />
                            <div className="description">13/11/1989<br />Demonstrace náměstí Zd. Nejedlého<br />Aktuality ČST</div>
                        </div>

                        <div className="list-item" onClick={() => { window.open('13-11-1989-demonstrace-namesti-zd-nejedleho-frantisek-smahel-muzeum-teplice', '_blank'); }}>
                            <SmahelPreview />
                            <div className="description">13/11/1989<br />Demonstrace náměstí Zd. Nejedlého<br />František Šmahel / muzeum Teplice</div>
                        </div>

                        <div className="list-item" onClick={() => { window.open('listopad 1989-ekotyden-ekologicka-debata-1-cast-archiv-very-bartoskove', '_blank'); }}>
                            <EkoTydenPreview />
                            <div className="description">Listopad 1989<br />Ekotýden - ekologická debata, 1.část<br />archiv Věry Bartoškové</div>
                        </div>

                        <div className="list-item" onClick={() => { window.open('listopad-1989-ekotyden-ekologicka-debata-2-cast-archiv-very-bartoskove', '_blank'); }}>
                            <EkoTydenPreview />
                            <div className="description">Listopad 1989<br />Ekotýden - ekologická debata, 2.část<br />archiv Věry Bartoškové</div>
                        </div>

                        <div className="list-item" onClick={() => { window.open('tiskova-konference-of-nedatovano-pavel-horak', '_blank'); }}>
                            <OfPreview />
                            <div className="description">Tisková konference OF<br />nedatováno<br />Pavel Horák</div>
                        </div>

                        <div className="list-item" onClick={() => { window.open('13-11-1989-radiova-komunikace-vb-teplice-1-cast-ustr', '_blank'); }}>
                            <RadioPreview />
                            <div className="description">13/11/1989<br />Radiová komunikace VB Teplice<br />1. část<br />ÚSTR</div>
                        </div>
                        <div className="list-item" onClick={() => { window.open('13-11-1989-radiova-komunikace-vb-teplice-2-cast-ustr', '_blank'); }}>
                            <RadioPreview />
                            <div className="description">13/11/1989<br />Radiová komunikace VB Teplice<br />2. část<br />ÚSTR</div>
                        </div>

                        <div className="list-item" onClick={() => { window.open('konference-of-teplice-archiv-very-bartoskove', '_blank'); }}>
                            <OfPreview />
                            <div className="description">Konference OF Teplice<br />archiv Věry Bartoškové</div>
                        </div>

                        <div className="list-item" onClick={() => { window.open('https://www.facebook.com/Hynek.Hanza.politik/videos/1015659498775766/UzpfSTEwMDAwMDI5ODIxMDQ0OToyOTA1NDY0MjA5NDczNDkx/', '_blank'); }}>
                            <JanaDvorska01Preview />
                            <div className="description">Sametová revoluce v Teplicích očima pamětníků<br />Post Bellum / město Teplice</div>
                        </div>
                        <div className="list-item" onClick={() => { window.open('https://www.pametnaroda.cz/cs/dvorska-jana-1960', '_blank'); }}>
                            <JanaDvorska02Preview />
                            <div className="description">Rozhovor s Janou Dvorskou<br />Paměť národa</div>
                        </div>
                        <div className="list-item" onClick={() => { window.open('https://www.pametnaroda.cz/cs/wanek-miroslav-1962', '_blank'); }}>
                            <WankemPreview />
                            <div className="description">Rozhovor s Miroslavem Wankem<br />Paměť národa</div>
                        </div>
                        <div className="list-item" onClick={() => { window.open('volani-radek-chmel', '_blank'); }}>
                            <VolaniPreview />
                            <div className="description">Volání<br />Radek Chmel</div>
                        </div>
                        <div className="list-item" onClick={() => { window.open('https://www.pametnaroda.cz/cs/sajdl-jan-1964', '_blank'); }}>
                            <JanSajdl01Preview />
                            <div className="description">Rozhovor s Janem Sajdlem<br />Paměť národa</div>
                        </div>
                        <div className="list-item" onClick={() => { window.open('https://www.pametnaroda.cz/cs/horak-pavel-1954', '_blank'); }}>
                            <PavelHorak01Preview />
                            <div className="description">Rozhovor s Pavlem Horakem<br />Paměť národa</div>
                        </div>
                        <div className="list-item" onClick={() => { window.open('https://www.pametnaroda.cz/cs/veselsky-petr-1955', '_blank'); }}>
                            <PetrVeselsky01Preview />
                            <div className="description">Rozhovor s Petrem Veselským<br />Paměť národa</div>
                        </div>
                    </div>
                </AudioVideo>

                <RedLine />

                <Actions>
                    <div className="title">Kde knihu zakoupit</div>
                    <div className="list">
                        <div className="left">
                            <div className="action">
                                <b>Freshlabels</b><br />(včetně ONLINE prodeje)<br />
                                Stanová 2<br />Teplice<br />
                                <a href="https://www.freshlabels.cz/" target="_blank">www.freshlabels.cz</a>
                            </div>
                        </div>

                        <div className="center">
                            <div className="action">
                                <b>Knihkupectví, antikvariát a galerie L&N</b><br />
                                Masarykova 400/15<br />Teplice<br />
                            </div>
                        </div>

                        <div className="right">
                            <div className="action">
                                <b>Antikvariát U Kostela</b><br />
                                Jiřího z Poděbrad 17/1<br />Teplice<br />
                            </div>
                        </div>
                    </div>
                </Actions>

                <Footer>
                    <div className="line-1" />
                    <div className="line-2" />
                    <div className="line-3" />
                    <div className="line-4" />
                    <div className="line-5">
                        <a href="https://www.facebook.com/inverze89/" title="Novinky na Facebooku" target="_blank">Novinky na Facebooku</a>
                    </div>
                </Footer>

            </Content>
        </ThemeProvider>
    );
}

export default IndexPage;
